<template>
  <div class="w-full h-full pa-3 product-packages-wrapper">
    <ProductPackages />
  </div>
</template>

<script>
export default {
  components: {
    ProductPackages: () => import('@/modules/productPackages/index.vue')
  }
}
</script>

<style scoped>
.product-packages-wrapper {
  background-color: white;
}
</style>
